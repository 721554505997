import { datesArray } from "../dateFunctions.js";
import { holidaysFull, holidaysISO } from "./easter.js";

export function processDates(date, activeDates, person) {
  // Generate a month of days for current person - according to date
  const datesPrepped = datesArray(date).map((day, index) => {
    const dayObj = new Date(day);
    const publicHoliday = holidaysFull(dayObj.getFullYear()).filter(
      (item) =>
        item.month === dayObj.getMonth() + 1 && item.day === dayObj.getDate()
       && (item.from ? dayObj?.getFullYear() >= item.from : true) 
    );
    const holidayDescript =
      publicHoliday && publicHoliday.length ? publicHoliday[0].description : "";
    const isPublicHoliday =
      publicHoliday && publicHoliday.length ? true : false;

    dayObj.setHours(11);
    const today = new Date();
    today.setHours(11);
    const dayNumber = dayObj.toLocaleDateString("pl-PL", { day: "numeric" });
    const dayName = dayObj.toLocaleDateString("pl-PL", { weekday: "long" });
    const dayNameShort = dayObj.toLocaleDateString("pl-PL", {
      weekday: "short",
    });

    const dayISO = dayObj.toISOString().slice(0, 10);
    const weekend =
      dayObj.getDay() === 0 || dayObj.getDay() === 6 ? dayObj.getDay() : false;
    const dayClass =
      "date" +
      (dayObj.getDay() === 0 || dayObj.getDay() === 6 || isPublicHoliday
        ? " weekend"
        : "") +
      (dayObj.toLocaleDateString() === today.toLocaleDateString()
        ? " today"
        : "");
    const active = person
      ? activeDates.some(
          (item) =>
            //parseInt(item.translatorID) === person.id && item.date === dayISO
            parseInt(item.employeeID) === person.employeeID && item.date === dayISO
        )
      : false;
    return {
      dayObj: dayObj, // date as object
      dayNumber: dayNumber,
      dayName: dayName,
      dayNameShort: dayNameShort,
      dayISO: dayISO,
      dayClass: dayClass,
      active: active,
      type: null,
      individualIndex: index,
      person: person ? person.id : false,
      weekend: weekend,
      publicHoliday: isPublicHoliday,
      holidayDescript: holidayDescript,
      off: weekend !== false || isPublicHoliday,
    };
  });

  activeDates
    .filter((item) =>
      person ? parseInt(item.employeeID) === person.employeeID : false
    )
    .forEach((item) => {
      const dates = datesPrepped.map((date) => date.dayISO);
      const index = dates.indexOf(item.date);
      if (datesPrepped[index]) {
        datesPrepped[index].type = item.type;
        datesPrepped[index].db = item.db;
        datesPrepped[index].approved = item.approved;
      }
      //  console.log(item.type);
    });

  /*  An incredibly complicated way to put holiday dates into date groups, 
  /*  including the intervening weekends, so that multi-day leaves can be acted on collectively.
  /*  Took a day and some of the evening to complete.
  */

  // Make an empty array to put multi-day holidays and the days in between into separate arrays of days
  const groups = [];
  // Initialize the id for individual groups to facilitate identifying
  let id = 0;
  // Iterate through the array of days taken from the db and super-charged with additional properties as datesPrepped
  datesPrepped.forEach((item, index, array) => {
    // If the item is falsy, go to next item
    if (!item) return;
    // Define the initial conditions to start a new group of dates
    if (
      index === 0 || // if it's the first element of the month
      (item.active && array[index - 1] && !array[index - 1].active) || // if this item is active but the last one wasn't
      (!item.active && array[index - 1] && array[index - 1].active) || // if this item is not active but the last one was
      item.type !== array[index - 1].type || // if the types of days off are different
      item.approved !== array[index - 1].approved // if one is approved and the other isn't
    ) {
      const newGroup = {
        id: id,
        active: item.active,
        type: item.type,
        db: item.db,
        approved: item.approved,
        content: [],
        offBetween: false,
      };
      if (!newGroup.type && array[index - 1] && array[index - 1].type) {
        newGroup.prevType = parseInt(array[index - 1].type);
      }
      groups.push(newGroup);
      // Increment the id
      id++;
    }
    if (groups.length) groups[groups.length - 1].content.push(item);
  });
  // Find the indices of public holiday/weekend day groups which are between two user-marked holiday groups:
  const offGroupIndices = [];
  groups.forEach((group, index, array) => {
    // If there's a group of days marked as holiday and another group that doesn't lie next to it in the calendar
    if (
      group.active &&
      array[index + 2] &&
      array[index + 2].active &&
      group.type === array[index + 2].type
    ) {
      // if between the two groups there are only public holidays/saturdays ("off")
      const allOff = groups[index + 1].content.every((item) => item.off);
      //console.log(groups[index + 1].content);
      if (groups[index + 1] && allOff) {
        groups[index + 1].offBetween = true;
        //group.nextType = groups[index + 1].type;
        offGroupIndices.push(groups.slice(index + 1, index + 2)[0].id);
      }
    }
  });
  //console.log(groups);
  const aggregateGroups = [];
  let previousGroup = null;
  let deleteIndices = [];
  groups.forEach((group, index, array) => {
    if (offGroupIndices.find((item) => item === index)) {
      let firstGroup = array[index - 1].content;
      let lastGroup = array[index + 1].content;
      if (
        previousGroup &&
        previousGroup.lastGroup[0].dayISO === firstGroup[0].dayISO
      ) {
        firstGroup = previousGroup.content;
        deleteIndices.push(previousGroup.id);
      }
      previousGroup = {
        id: array[index - 1].id,
        active: array[index - 1].active,
        aggregate: true,
        type: array[index - 1].type,
        firstGroup: firstGroup,
        lastGroup: lastGroup,
        content: [...firstGroup, ...array[index].content, ...lastGroup],
      };
      aggregateGroups.push(previousGroup);
    } else if (
      !offGroupIndices.find((item) => item === index - 1) &&
      !offGroupIndices.find((item) => item === index + 1)
    ) {
      aggregateGroups.push(group);
    }
  });

  deleteIndices.forEach((i) => {
    //const item = aggregateGroups.find(item => item.id === i).map(item => item.id);
    const index = aggregateGroups.map((item) => item && item.id).indexOf(i);
    aggregateGroups[index] = null;
    //console.log(index);
  });

  aggregateGroups &&
    aggregateGroups.forEach((group, index, array) => {
      if (
        group &&
        array[index - 1] &&
        array[index - 1].active &&
        !group.active &&
        array[index + 1] &&
        array[index + 1].active &&
        parseInt(array[index - 1].type) === parseInt(array[index + 1].type)
      )
        group.betweenActive = true;
    });
  return {
    aggregateGroups: aggregateGroups,
    datesPrepped: datesPrepped,
  };
}

/**
 * Date aggregator developed in 2023
 * */

/**  This function checks whether days before a given date
 * - within a particular $space$ of days - are all holidays or Saturdays
 * */
function arePrevHolidays(date, space) {
  const d1 = new Date(date);
  const holidays = holidaysISO(d1.getFullYear());

  let lastHoliday = false;
  if (space > 5) return false;
  for (let i = 1; i < space; i++) {
    if (i === 1 || lastHoliday === true) {
      d1.setDate(date.getDate() - i);
      lastHoliday =
        d1.getDay() === 6 ||
        d1.getDay() === 0 ||
        holidays.includes(d1.toISOString().slice(0, 10));
      //console.log(lastHoliday,d1.toLocaleDateString(), d1.getDay())
    }
  }
  //console.log(lastHoliday);
  return lastHoliday;
}

/**
 * This function checks whether the day before is also a specified day off
 * @param {*} dates
 * @param {*} separators the names of properties of the object featuring a date, which break groups when not the same
 * @returns
 */
export function aggregateDates(dates, separators) {
  // create an empty array to place the aggregated dates into
  const aggregates = [];

  // loop through the dates, which crucially feature additional information, to sort out the groups
  // the actual dates - year-month-day are strings in the property 'date'
  console.log(dates.map( d => ({d: d.date, a: d.approved})));
  dates.forEach((item, index, arr) => {
    const prev = arr[index - 1];
    const next = arr[index + 1];

    const prevDate = prev?.date ? new Date(prev.date) : undefined;
    const nextDate = next?.date ? new Date(next.date) : undefined;

    const currDate = new Date(item.date);
    const dayAfter = new Date(currDate);
    dayAfter.setDate(currDate.getDate() + 1);
    const dayBefore = new Date(currDate);
    dayBefore.setDate(currDate.getDate() - 1);

    const dates = {
      prev: prev ? new Date(prev.date).toLocaleDateString() : undefined,
      curr: new Date(item.date).toLocaleDateString(),
      next: next ? new Date(next.date).toLocaleDateString() : undefined,
      space: prev ? (currDate - prevDate) / 60 / 60 / 24 / 1000 : undefined,
    };

    const separatorChange = separators.map(separator => {
      return separator && prev && (prev[separator] !== item[separator])
   })
    const separateHere = separatorChange.reduce((a,b) => a || b, false);
    console.log(separatorChange, separateHere ? "sep-" + item.date : item.date + "-no-sep");
    

    // create new group
    if (separateHere) {
      aggregates.push([item]);
    }
    // continue group
    else if (dates.space === 1 || arePrevHolidays(currDate, dates.space)) {
      aggregates.at(-1).push(item);
    } 
    // create new group
    else {
      aggregates.push([item]);
    }
  });
  return aggregates;
}
